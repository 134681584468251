import React from "react";
import ValueLabelDisplay from "../../../../../../organisms/ValueLabelDisplay";
import moment from "moment/moment";

const View = ({ data }) => {
  return (
    <>
      <ValueLabelDisplay label="User Name">{data?.user_name}</ValueLabelDisplay>
      <ValueLabelDisplay label="Email Id">
        {data?.business_email}
      </ValueLabelDisplay>
      <ValueLabelDisplay label="Title">{data?.title}</ValueLabelDisplay>
      <ValueLabelDisplay label="Description">
        {data?.description}
      </ValueLabelDisplay>
      <ValueLabelDisplay label="Requested At">
        {data?.requested_at
          ? moment(data.requested_at).format("YYYY-MM-DD, h:mm A")
          : "N/A"}
      </ValueLabelDisplay>
    </>
  );
};

export default View;
