/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Company from "../../../assets/images/NoContent.png";
import { errorStyles } from "../../../assets/styles/toast";
import CustomButton from "../../atoms/CustomButton";
import Modal from '../modals/Modal'
import Upload from '../../icons/Upload'
import Edit from '../../icons/Edit'
import Delete from '../../icons/Delete'
import './image.scss'

// ... (previous imports)

// To get the cropped image with new details
const getCroppedImg = (image, crop, fileName) => {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        // console.error("Canvas is empty");
        reject(new Error("Canvas is empty"));
        return;
      }

      const file = new File([blob], fileName, { type: "image/jpeg" });
      blob.name = fileName;
      window.URL.revokeObjectURL(image.src);
      const croppedImageUrl = window.URL.createObjectURL(blob);
      resolve({ file, croppedImageUrl });
    }, "image/jpeg");
  });
};

const ImageSelector = React.memo(
  ({
    handleFile,
    type,
    style,
    selectedImage,
    notShow,
    description,
    maxFileSize = 1,
    showRemove = true,
    aspect = 1,
    onRemove,
  }) => {
    const [image, setImage] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [finalImage, setFinalImage] = useState(null);
    const [finalImageFile, setFinalImageFile] = useState(null);
    const [isCropModalOpen, setIsCropModalOpen] = useState(false);
    const [cropActive, setCropActive] = useState(false);
    const [crop, setCrop] = useState({
      unit: "%",
      width: 30,
      aspect: aspect,
    });

    // dECLARING REFS
    const hiddenFileInput = useRef(null);
    const imageRef = useRef(null);

    useEffect(() => {
      if (imageRef.current && crop.width !== 0 && crop.height !== 0) {
        makeClientCrop(crop);
      }
    }, [crop, imageRef.current]);

    // Setting initially selected image as final one
    useEffect(() => {
      setFinalImage(selectedImage);
    }, [selectedImage]);

    // handle hidden file input
    const handleClick = () => {
      if (hiddenFileInput.current) {
        hiddenFileInput.current.value = "";
      }
      hiddenFileInput.current.click();
    };

    // handling remove
    const handleRemove = () => {
      setImage(null);
      setImageFile(null);
      setFinalImage(null);
      setFinalImageFile(null);
      handleFile(null);

      onRemove();

      // Clear the file input by setting its value to an empty string
      if (hiddenFileInput.current) {
        hiddenFileInput.current.value = "";
      }
    };

    // Step 1
    const handleImageChange = (event) => {
      setImage(null);
      setImageFile(null);
      const file = event.target.files[0];

      if (file) {
        // Check if the file size is less than 1 MB (1 MB = 1024 * 1024 bytes)
        if (file.size > maxFileSize * 1024 * 1024) {
          toast.error(`Image size should be less than ${maxFileSize} MB`, {
            duration: 2000,
            style: errorStyles,
          });
          // setImage(null);
          return;
        }

        if (file.type.startsWith("image/")) {
          const reader = new FileReader();
          reader.onload = () => {
            // Create an image element to get the dimensions
            const img = new Image();
            img.src = reader.result;

            img.onload = () => {
              // Check if the image width and height are greater than or equal to your minimum size
              const minWidth = 10; // Set your minimum width
              const minHeight = 10; // Set your minimum height

              if (img.width >= minWidth && img.height >= minHeight) {
                setImage(reader.result);
                setImageFile(file);
                setIsCropModalOpen(true);
              } else {
                toast.error(
                  `Image dimensions should be at least ${minWidth}x${minHeight}`,
                  {
                    duration: 2000,
                    style: errorStyles,
                  }
                );
              }
            };
          };

          reader.readAsDataURL(file);
        } else {
          toast.error("Only JPG, PNG, and JPEG are allowed", {
            duration: 2000,
            style: errorStyles,
          });
        }
      } else {
      }
    };

    // HandleModalClose
    const handleCropModalClose = () => {
      if (hiddenFileInput.current) {
        hiddenFileInput.current.value = "";
      }
      setIsCropModalOpen(false);
      setImage(null);
      setImageFile(null);
      handleFile(null);
    };

    // Cropping Functionalities
    const handleInitialCrop = () => {
      setCrop({
        unit: "%",
        width: 30,
        aspect: aspect,
      });
    };

    const onImageLoaded = (image) => {
      imageRef.current = image;

      const minSize = Math.min(image.width, image.height);
      const initialCrop = {
        unit: "%",
        width: 100, // Set the initial width (in percentage)
        aspect: aspect, // Set the aspect ratio (1 for a square)
      };

      setCrop(initialCrop);
    };

    const onCropChange = (newCrop) => {
      // Ensure the width and height are equal
      const equalSizeCrop = {
        ...newCrop,
        width: Math.min(newCrop.width, newCrop.height),
        height: Math.min(newCrop.width, newCrop.height),
      };

      // aspect===1 ? setCrop(equalSizeCrop):setCrop(newCrop);
      const cropWithAspect = {
        ...newCrop,
        aspect: aspect,
      };

      setCrop(cropWithAspect);
    };

    const onCropComplete = (crop, percentCrop) => {
      makeClientCrop(crop);
    };

    const makeClientCrop = async (crop) => {
      if (imageRef.current && crop.width && crop.height) {
        const minSize = Math.min(crop.width, crop.height);
        let updatedCrop = {
          ...crop,
          width: crop.width, // default width
          height: crop.height, // default height
        };

        if (aspect === 1) {
          updatedCrop = {
            ...crop,
            width: minSize,
            height: minSize,
          };
        }
        const { file, croppedImageUrl } = await getCroppedImg(
          imageRef.current,
          updatedCrop,
          "newFile.jpeg"
        );
        setFinalImage(croppedImageUrl);
        // setSrc(croppedImageUrl)
        setFinalImageFile(file);
      }
    };

    // handling direct confirm
    const handleDirectConfirm = () => {
      handleFile(imageFile); //emit file to parent
      setFinalImage(image); // set initial image as final
      setIsCropModalOpen(false); // close the crop modal
    };

    // handle crop confirm
    const handeConfirmCrop = () => {
      makeClientCrop();
      handleFile(finalImageFile);
      setCropActive(false);
      setIsCropModalOpen(false);
    };

    // Styles
    const styles = {
      ...style,
    };

    // console.log("styles", styles);
    return (
      <div className="image-selector">
        {finalImage ? (
          <img
            src={finalImage}
            alt=""
            loading="lazy"
            className="image-preview"
            style={{ ...styles, padding: 0 }}
          />
        ) : (
          <img
            loading="lazy"
            src={Company}
            className="image-preview"
            alt="Profile"
            style={styles}
          />
        )}

        {!notShow && (
          <>
            <div>
              <p className="view-muted mb-4" style={{ maxWidth: "245px" }}>
                {description}
              </p>
              <div className="d-flex">
                <CustomButton
                  icon={finalImage ? <Edit/> : <Upload/>}
                  text={finalImage ? "Edit" : "Upload"}
                  type={finalImage ? "btn-outline-primary" : "btn-outline-dark"}
                  handleClick={handleClick}
                />
                {finalImage && showRemove && (
                  <CustomButton
                    icon={<Delete/>}
                    text="Remove"
                    classes="ms-3"
                    type="btn-outline-danger"
                    handleClick={handleRemove}
                  />
                )}
              </div>
            </div>

            <input
              type="file"
              onChange={handleImageChange}
              accept=".jpg, .jpeg, .png"
              ref={hiddenFileInput}
              style={{ display: "none" }}
            />

            {isCropModalOpen && (
              <Modal
                isActive={isCropModalOpen}
                onClose={handleCropModalClose}
                title="Confirm Image"
                width="550px"
                bodyStyles={{ paddingLeft: "16px", paddingRight: "16px" }}
              >
                <>
                  {!cropActive && (
                    <div style={{ width: "100%" }}>
                      <img
                        src={image}
                        style={{ width: "100%" }}
                        loading="lazy"
                      ></img>
                      <div
                        className="image-bottom-container"
                      >
                        <button
                          className="btn btn-outline-primary"
                          onClick={() => {
                            // return handleInitialCrop(), setCropActive(true);
                            handleInitialCrop();
                            setCropActive(true);
                          }}
                        >
                          Crop
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={handleDirectConfirm}
                        >
                          Confirm
                        </button>
                        {/* <button className="btn btn-outline-danger" onClick={()=>{return (setImageCancel(true),setCropModal(false))}}>Cancel</button> */}
                        {/* onClick={()=>{return (setSrc(''),setCroppedImageUrl(''))}} */}
                      </div>
                    </div>
                  )}
                  {cropActive && (
                    <div>
                      <ReactCrop
                        src={image}
                        crop={crop}
                        onImageLoaded={onImageLoaded}
                        onComplete={onCropComplete}
                        onChange={onCropChange}
                        allowResize={true}
                        aspect={aspect}
                      />
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          gap: "20px",
                        }}
                      >
                        <button
                          className="btn btn-outline-primary"
                          onClick={handeConfirmCrop}
                        >
                          Confirm Crop
                        </button>
                        <button
                          className="btn btn-outline-danger"
                          onClick={handleCropModalClose}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </>
              </Modal>
            )}
          </>
        )}
      </div>
    );
  }
);

export default ImageSelector;
